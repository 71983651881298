import type { PublicCorporateLocales } from "@/modules/corporate/types/locales"
import type { Page } from "@/modules/corporate/types/pages"
import type { AllSections } from "@/modules/corporate/types/sections"
import type { contentTypes, Personas } from "@/modules/kontent/generated-types"

export const URL_PATHNAME = "corporate"
export const DEFAULT_PERSONA: Personas = "recruiter"

export const PAGE_TYPES: (keyof typeof contentTypes)[] = ["growth___corporate_page"]
export const SUB_PAGE_TYPES: (keyof typeof contentTypes)[] = ["growth___corporate_blog_article"]

export const SECTIONS_WITH_SUBPAGE: AllSections["type"][] = ["growth___corporate_page_section___blog"]
export const SECTIONS_WITH_PAGINATION: AllSections["type"][] = [
  "growth___corporate_page_section___blog",
  "growth___corporate_page_section___network",
]

export const PagePropertiesToAvoidWhenResolvingNavigation: (keyof Page)[] = ["childrenPages", "sections"]
export const PagePropertiesToAvoidWhenResolvingPage: (keyof Page)[] = ["childrenPages", "contactPage", "sections"]
export const PagePropertiesToAvoidWhenResolvingPageNavigation: (keyof Page)[] = ["contactPage", "sections"]

export const LOCAL_STORAGE_FORM_DATA_KEY = "corporate_form_data"

export const SESSION_STORAGE_CAMPAIGN_TRACKING_DATA_KEY = "corporate_campaign_tracking"
export const SESSION_STORAGE_REFERRER_TRACKING_KEY = "corporate_referrer_tracking"

const UTM_PARAMS = ["utm_campaign", "utm_content", "utm_source", "utm_medium", "utm_term"] as const
const CLICK_IDS_PARAMS = ["gclid", "sfcid", "msclkid"] as const

export const ALLOWED_TRACKING_PARAMETERS = [...CLICK_IDS_PARAMS, ...UTM_PARAMS] as const

export type TrackingParameters = (typeof ALLOWED_TRACKING_PARAMETERS)[number]

export const PUBLIC_CORPORATE_LOCALES: PublicCorporateLocales[] = ["fr", "de", "en"]
